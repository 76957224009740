import BaseRequest from "../BaseRequest"
import appUtils from "../../utils/appUtils"

export default class AuthRequest extends BaseRequest {
  getTimeSlot(p) {
    return this.post(`go/getdoctorslotv2`, p)
  }
  getTimeSlotByCrID(p) {
    return this.post(`go/getDoctorSlotByCrId`, p)
  }
  getIcd10(p) {
    return this.get(`auth/icd10`, p)
  }
  getDoctor(p) {
    return this.get(`auth/doctor`, p)
  }
  deleteMeetRoom(meet_room_id) {
    return this.post(`auth/meet-room/${meet_room_id}/complete`)
  }
  updateMeetRoom(meet_room_id, params) {
    return this.post(`auth/meet-room/${meet_room_id}/update`, params)
  }
  getConversations(params) {
    return this.get("auth/my-direct-conversations", params)
  }
  postChangePassword(params) {
    return this.post("auth/change-password", params)
  }

  postLoginFacebook(data = {}) {
    return this.post("auth/facebook", data)
  }

  login(data = {}) {
    // return this.post('auth/login', data)
    return this.post("go/auth/login", data)
  }

  logout() {
    return this.post("auth/logout")
  }

  me() {
    try {
      // return this.get('auth/me')
      // return this.get('auth/doctor/me')
      return this.get("go/auth/doctor/v2/me")
    } catch (e) {
      appUtils.onClearUser()
      return window.$router.push({ path: "/login" })
    }
  }

  getTemplatesListing(params) {
    return this.get(`auth/templates`, params)
  }

  getConnectyUser(params = {}) {
    return this.get(`auth/connecty-user`, params)
  }

  signUp(data = {}) {
    return this.post("applies", data)
  }

  postForgot(data = {}) {
    return this.post("users/forgot", data)
  }
  postForgotGo(data = {}) {
    return this.post("go/public/users/forget-pass", data)
  }

  postPasswordReset(data = {}) {
    return this.post("users/new-password", data)
  }
  getPersonConnectyUser(id) {
    return this.get(`auth/persons/${id}/connecty-user`)
  }
  postDoctorRegister(params) {
    return this.post(`doctor-register`, params)
  }
  postResendEmail(params) {
    return this.post(`resend-email`, params)
  }
  postInvites(params) {
    return this.post(`auth/invites`, params, null, false)
  }
  getBank() {
    return this.get(`banks`)
  }
  getInvoices(id, params) {
    return this.get(`invoices/${id}`, params)
  }
  postInvoices(id, params) {
    return this.post(`invoices/${id}/status`, params)
  }
  postBankAccounts(id, params) {
    return this.post(`invoices/${id}/bank-accounts`, params)
  }
  postInvoicesUpload(id, params) {
    return this.post(`invoices/${id}/upload`, params)
  }
  getPersonQuicBloxUser(id, params = {}) {
    return this.get(`auth/persons/${id}/qb-user`, params)
  }
  getQuicBloxUser(params = {}) {
    return this.get(`auth/qb-user`, params)
  }
  serachCodeDoctor(params) {
    return this.get(`auth/doctors`, params)
  }
  getDoctorInfo(id) {
    return this.get(`auth/doctors/${id}`)
  }
  visit() {
    return this.get(`auth/visit`)
  }
  getDoctorNotes(data) {
    return this.get(`auth/doctor-notes/`, data)
  }
  signUpDevice(data) {
    return this.post(`auth/devices`, data)
  }
  getRequestsTotal(params) {
    return this.get(`auth/requests-total`, params)
  }
  postPasswordAgain(params) {
    return this.post(`auth/password`, params)
  }
  getQuestionGroups(params) {
    return this.get(`auth/question-groups`, params)
  }
  getQuestionByGroups(id, params) {
    return this.get(`auth/question-groups/${id}/questions`, params)
  }
  getQuestionByGroupsV2(id, params) {
    return this.get(`auth/question-groups/${id}`, params)
  }
  getAnswersByGroups(id, params) {
    return this.get(`auth/question-groups/${id}/answers`, params)
  }
  getThreads() {
    return this.get(`auth/threads`)
  }
  getThreadsMessage(id, params) {
    return this.get(`auth/threads/${id}/messages`, params)
  }
  getThreadsById(id) {
    return this.get(`auth/threads/${id}`)
  }
  postMessageUpload(id, params, header) {
    return this.post(`auth/thread-messages/${id}/upload`, params, header)
  }
  getPersonHistories(id, params) {
    return this.get(`auth/persons/${id}/histories`, params)
  }
  getTags() {
    return this.get(`auth/tags`)
  }
  getTagsId(id) {
    return this.get(`auth/tags/${id}`)
  }
  getMedicines(params) {
    return this.get(`auth/medicines`, params)
  }
  getTestTemplates(id, params) {
    return this.get(`auth/person-test-template/group`, params)
  }
  getTestTemplatesItems(params) {
    return this.get(`auth/person-test-template`, params)
  }
  getStringeeToken(params) {
    return this.post(`auth/stringee-token`, params)
  }
  getUserInfo(id) {
    return this.post(`auth/user/${id}`)
  }
  showCommentHistory(comment_id, params) {
    return this.get(`auth/comment/${comment_id}/show`, params)
  }
  sendPushNotifyCallAndroid(params) {
    return this.post(`auth/call-android`, params)
  }
  getLinkDownloadAws(params, header, showMessage = true) {
    return this.post("core/v1/uploads/s3-get-presigned", params, header, showMessage)
  }
  getLinkHodoResourceAws(params, header, showMessage = true) {
    return this.post("core/v1/uploads/s3-get-rc-presigned", params, header, showMessage)
  }
  verifyJoinLink(id, params) {
    return this.post(`core/v1/doctor/clinics/${id}/verify-invite-doctor`, params)
  }
  sendOtpEmail(params) {
    return this.post(`core/v1/auth/send-email-otp`, params)
  }
  loginOtp(params) {
    return this.post(`core/v1/auth/doctor-login-otp`, params)
  }
  checkHasPw() {
    return this.post(`core/v1/auth/has-password`)
  }
  getCredential(params) {
    return this.post("core/v1/auth/multiple-meeting-doctor-credential", params)
  }
  addMeetMembers(id, params) {
    return this.post(`auth/meet-room/${id}/add-members`, params)
  }
  getPublicMdtCheckinMembers(params) {
    return this.get("auth/public-mdt-checkin", params, null, false)
  }
  publicMdtCaseStatus(id, params) {
    return this.post(`auth/public-mdt-cases/${id}/status`, params)
  }
  acceptInviteDoctorWs(params, hd, sm) {
    return this.post(`workspaces/accept-invitation`, params, hd, sm)
  }
  verifyInviteDoctorWsLink(params, hd, sm) {
    return this.get(`workspaces/check-invitation`, params, hd, sm)
  }
  getChatList(params, hd, sm) {
    return this.get(`auth/chat/list`, params, hd, sm)
  }
  getChatListV2(params) {
    return this.get(`go/conversation/chat/list`, params)
  }
  directChatTo(params, hd, sm) {
    return this.post(`auth/direct-conversations`, params, hd, sm)
  }
  leaveMeet(meet_room_id, hd, sm) {
    return this.post(`auth/meet-room/${meet_room_id}/leave`, hd, sm)
  }
  getUrlS3File(params) {
    return this.get(`auth/hodo-resource/url`, params)
  }
  getLinkDownloadAwsRc(params, header, showMessage = true) {
    return this.post("core/v1/uploads/s3-get-rc-presigned", params, header, showMessage)
  }
  getInfoConversation(id) {
    return this.get(`auth/chat/${id}/conversation`, null, null, false)
  }
  setLanguageForUser(data) {
    if (!data) return

    try {
      return this.post(`go/auth/lang`, data)
    } catch (error) {
      console.log(error)
    }
  }
  uploadPatientFiles(params) {
    return this.post("auth/patient-visits/files", params)
  }
  deletePatientFile(idFile) {
    return this.delete(`auth/patient-visits/files/${idFile}`)
  }
  getOtpCodeWithEmail(params) {
    return this.post(`go/core/emails/sendEmail`, params)
  }
  confirmOtpCodeInRegOrg(params) {
    return this.post(`go/core/emails/confirmCode`, params)
  }
  getVirgilGenerationToken() {
    return this.get(`${process.env.VUE_APP_URL_VIRGIL_API}/api/go/virgilSecurity/generateToken`)
  }
}
